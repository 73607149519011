<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="text-center mb-8">
          <calendesk-information-message>
            {{ $trans("notifications_packages_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="item in packages"
          :key="item.quantity"
          cols="12"
          sm="6"
          md="4"
        >
          <v-container
            class="cd-radius cd-outline elevation-2"
            :class="{
              c_dark: item.featured,
              'c_dark--text': !item.featured,
              'white--text': item.featured,
              'mt-md-n10': item.featured,
            }"
          >
            <v-row>
              <v-col class="c-text-18 font-weight-bold text-center mt-2">
                <div v-if="item.featured" class="text-center mb-4">
                  <span
                    class="text-uppercase font-weight-bold c-text-12 c_orange--text"
                    >{{ $trans("plans_featured") }}</span
                  >
                  <v-icon color="c_orange">$fire</v-icon>
                </div>
                <div v-else class="mt-10 mt-md-0"></div>
                {{
                  isEmailPackage
                    ? $trans("email_package")
                    : $trans("sms_package")
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="c-text-36 font-weight-bold text-center">
                  {{ item.quantity.toLocaleString() }}
                </div>
                <div
                  v-if="item.extra"
                  class="text-center mt-6 font-weight-medium c-text-18"
                >
                  <span>
                    + {{ item.extra.toLocaleString() }}
                    {{ $trans("extra_notification") }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-divider class="mx-2" :dark="item.featured" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-container fluid>
                  <v-row v-if="item.extra">
                    <v-col class="pt-0 mt-0">
                      <v-icon color="green"> $check </v-icon>
                      + {{ item.extra.toLocaleString() }}
                      {{ $trans("extra_notification") }} 😍
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 mt-0">
                      <v-icon color="green"> $check </v-icon>
                      {{ $trans("never_expires") }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div>
                        <span class="font-weight-medium c-text-18">
                          {{
                            packagePrice(item.quantity)
                              | money(
                                adminConfiguration
                                  ? adminConfiguration.tenant_currency
                                  : null
                              )
                          }} </span
                        ><br />
                        <template v-if="vatAmount(item.quantity)">
                          <small
                            >(+
                            {{
                              vatAmount(item.quantity)
                                | money(
                                  adminConfiguration
                                    ? adminConfiguration.tenant_currency
                                    : null
                                )
                            }}
                            {{ $trans("invoice_vat") }} )</small
                          >
                        </template>
                        <span class="text-caption">
                          {{ $trans("once_no_vat") }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  class="pa-5 text-none tag-go-to-plans c_white_1--text mb-4"
                  color="c_dark_3"
                  rounded
                  :small="$vuetify.breakpoint.mdAndDown"
                  elevation="0"
                  @click="pay(item.quantity)"
                >
                  <span class="font-sans-bold">
                    {{ $trans("select") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <three-ds-dialog
      v-if="show3DSDialog"
      :show="show3DSDialog"
      :src="stripe3DsSrc"
      @close="closeConfirmDialogAnd3DsFrame"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import {
  minNumber,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import threeDSActions from "@/calendesk/mixins/threeDSActions";
import ThreeDsDialog from "@/components/Dialogs/ThreeDsDialog";
import {
  chargeUser,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "BuyNotificationDialog",
  components: { CalendeskInformationMessage, ThreeDsDialog },
  mixins: [threeDSActions, sharedActions],
  data() {
    return {
      quantity: 1000,
      vatRate: 23,
      package: null,
      isLoading: false,
      rules: {
        minNumber,
        required,
      },
      availableSMSPackages: [
        {
          quantity: 5000,
          extra: 500,
          featured: false,
        },
        {
          quantity: 10000,
          extra: 2000,
          featured: true,
        },
        {
          quantity: 20000,
          extra: 5000,
          featured: false,
        },
        {
          quantity: 200,
          extra: 0,
          featured: false,
        },
        {
          quantity: 500,
          extra: 0,
          featured: false,
        },
        {
          quantity: 1000,
          extra: 0,
          featured: false,
        },
      ],
      availableEmailPackages: [
        {
          quantity: 10000,
          extra: 1000,
          featured: false,
        },
        {
          quantity: 20000,
          extra: 5000,
          featured: true,
        },
        {
          quantity: 50000,
          extra: 20000,
          featured: false,
        },
        {
          quantity: 1000,
          extra: 0,
          featured: false,
        },
        {
          quantity: 2000,
          extra: 0,
          featured: false,
        },
        {
          quantity: 5000,
          extra: 0,
          featured: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      defaultPaymentMethod: "payments/getDefaultPaymentMethod",
      adminConfiguration: "setup/getAdminConfiguration",
      invoiceData: "payments/getInvoiceData",
      getIsReverseChargeApplied: "payments/getIsReverseChargeApplied",
    }),
    isEmailPackage() {
      return (
        this.package && this.package.slug && this.package.slug.includes("email")
      );
    },
    invoiceDataFilled() {
      return (
        this.invoiceData &&
        this.invoiceData.invoice_name !== null &&
        this.invoiceData.invoice_street !== null &&
        this.invoiceData.invoice_postal_code !== null &&
        this.invoiceData.invoice_city !== null &&
        this.invoiceData.invoice_country_iso_code
      );
    },
    packages() {
      if (this.isEmailPackage) {
        return this.availableEmailPackages;
      }

      return this.availableSMSPackages;
    },
  },
  created() {
    this.$on("ThreeDsSuccess", this.purchaseSuccess);
    this.$on("ThreeDsFailed", this.purchaseFail);

    this.package = this.getDialog.data;
  },
  beforeDestroy() {
    this.$off("ThreeDsSuccess", this.purchaseSuccess);
    this.$off("ThreeDsFailed", this.purchaseFail);
  },
  methods: {
    ...mapActions({
      fetchRequiredAppComponents: "setup/fetchRequiredAppComponents",
      packagesPurchase: "packages/packagesPurchase",
      fetchUsage: "notification/fetchUsage",
    }),
    vatAmount(quantity) {
      if (this.getIsReverseChargeApplied) {
        return 0;
      }

      return this.packagePrice(quantity) * (this.vatRate / 100);
    },
    packagePrice(quantity) {
      if (this.package) {
        const value = this.package.price.amount;
        return quantity * value;
      }

      return 0;
    },
    pay(quantity) {
      pushEvent("buyNotificationsValidation");

      if (!this.invoiceDataFilled) {
        errorNotification(
          "invoice_data_missing",
          null,
          false,
          {},
          { name: "billing", params: { tab: "data" } },
          this.$trans("go_to_tab")
        );

        return;
      }

      if (!this.defaultPaymentMethod) {
        errorNotification(
          "payment_method_missing",
          null,
          false,
          {},
          { name: "billing", params: { tab: "data" } },
          this.$trans("add_payment_method")
        );

        return;
      }

      const payload = {
        quantity: quantity,
        payment_method_identifier: this.defaultPaymentMethod.id,
        packages: [this.package.slug],
      };

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("confirmation_modal_header"),
        warning: this.$trans("buy_package_confirmation_question"),
        confirmationText: this.$trans("buy_package_confirmation_button"),
        confirmationButtonColor: "green",
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          this.packagesPurchase(payload)
            .then((data) => {
              const redirectRequire = this.auth3dsRedirectRequiredForData(data);
              if (redirectRequire) {
                this.open3DsFrame(data);
              } else {
                this.purchaseSuccess();
              }
            })
            .catch((error) => {
              this.purchaseFail(error);
            });
        },
      });
    },
    purchaseSuccess(quantity) {
      pushEvent("buyNotificationsSuccess", {
        quantity: quantity,
        price: Math.round((this.package.price.amount / 100) * 100) / 100,
        slug: this.package.slug,
        amount: Math.round((this.packagePrice / 100) * 100) / 100,
        currency: this.adminConfiguration.tenant_currency,
      });

      chargeUser(
        this.package.slug,
        this.packagePrice,
        this.adminConfiguration.tenant_currency
      );

      this.setIsSending(true);
      setTimeout(this.reloadPackages, 5000);
      successNotification("buy_package_success");
      this.clearCache("invoices/fetchInvoices");
      this.closeConfirmDialog();
      this.$emit("close");
      this.setCommonDialogLoader(false);
    },
    purchaseFail(error) {
      pushEvent("buyNotificationsFailed", {
        error: error,
      });

      errorNotification(
        "buy_package_failed",
        error,
        false,
        {},
        { name: "billing", params: { tab: "data" } },
        this.$trans("change_payment_method")
      );
      this.setCommonDialogLoader(false);
      this.closeConfirmDialog();
      this.$emit("close");
    },
    closeConfirmDialogAnd3DsFrame() {
      this.setCommonDialogLoader(false);
      this.closeConfirmDialog();

      this.close3DsFrame();
    },
    reloadPackages() {
      this.fetchRequiredAppComponents().finally(() => {
        this.fetchUsage().finally(() => {
          this.setIsSending(false);
        });
      });
    },
  },
};
</script>
